import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { sContainer, sTitle, sHtmlList } from '../style'
import Seo from '../components/Seo'
import { useLangContext } from '../context/lang.context'

export default function DocumentTemplate({ data }) {
  const { lang } = useLangContext()
  let document = data.docs.nodes.find((node) => node.lang.slug === lang)
  if (!document) document = data.docs.nodes[0]

  const { title, slug, body, description, noindex } = document

  return (
    <Layout white>
      <Seo title={title} desc={description} url={slug} noIndex={noindex} />
      <section css={sContainer}>
        <h1 css={sTitle}>{title}</h1>
        <article
          css={sHtmlList}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}></article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    docs: allContentfulDocument(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        slug
        lang {
          slug
        }
        title
        noindex
        description
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
